import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton, TextField } from '@mui/material';
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from '@mui/icons-material';

const TablePaginationActions = (props) => {
  const { count } = props;
  const { page: pageP } = props;
  const { rowsPerPage } = props;
  const { onPageChange } = props;
  const pages = Math.ceil(count / rowsPerPage);

  const [page, setPage] = useState(pageP);

  const boxProps = {
    sx: {
      flexShrink: 0,
      ml: 2.5,
    },
  };
  const iconButton1Props = {
    onClick(event) {
      onPageChange(event, 0);
    },
    disabled: page === 0,
    'aria-label': 'first page',
  };
  const iconButton2Props = {
    onClick(event) {
      onPageChange(event, page - 1);
    },
    disabled: page === 0,
    'aria-label': 'previous page',
  };
  const iconButton3Props = {
    onClick(event) {
      onPageChange(event, page + 1);
    },
    disabled: page >= Math.ceil(count / rowsPerPage) - 1,
    'aria-label': 'next page',
  };
  const iconButton4Props = {
    onClick(event) {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    },
    disabled: page >= Math.ceil(count / rowsPerPage) - 1,
    'aria-label': 'last page',
  };
  const pageProps = {
    value: (page + 1),
    onBlur(event) {
      // clearTimeout(timer.current);

      if (pageP !== page) {
        onPageChange(event, page);
      }
    },
    onKeyDown(event) {
      if (event.key === 'Enter') {
        // clearTimeout(timer.current);

        if (pageP !== page) {
          onPageChange(event, page);
        }
      }
    },
    onChange(event) {
      let newPage = Number(event.target.value) || 1;
      if (newPage <= 0) newPage = 1;
      if (newPage >= pages) newPage = pages;
      newPage -= 1;
      setPage(newPage);
      // clearTimeout(timer.current);

      // timer.current = setTimeout(() => {
      //   if (props.page !== newPage) {
      //     onPageChange(event, newPage);
      //   }
      // }, 1000);
    },
    InputLabelProps: { shrink: false },
    sx: {
      // '& .MuiInputBase-input': { px: 0 },
      '& .MuiOutlinedInput-root': { bgcolor: '#fff' },
      // '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
      width: '60px',
    },
    variant: 'outlined',
    size: 'small',
    // type: 'number',
  };

  return (
    <Box {...boxProps}>
      <IconButton {...iconButton1Props}>
        <FirstPage />
      </IconButton>
      <IconButton {...iconButton2Props}>
        <KeyboardArrowLeft />
      </IconButton>
      {pages > 1 && (<TextField {...pageProps} />)}
      { /* pages > 1 && (
        <ButtonGroup>
          <TextField {...pageProps} />
          {page !== pageP && (<IconButton><Search /></IconButton>)}
        </ButtonGroup>) */ }
      <IconButton {...iconButton3Props}>
        <KeyboardArrowRight />
      </IconButton>
      <IconButton {...iconButton4Props}>
        <LastPage />
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
