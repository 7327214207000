import React from 'react';
import { v4 } from 'uuid';

import { Button } from '@mui/material';

const ButtonsForm = (props) => { // now only multiple
  const { form } = props;
  const { setForm } = props;

  const { name } = props;
  let value = props.value || [];
  const { values } = props;

  return (values || []).map((item) => {
    const btnProps = {
      key: v4(),
      size: 'small',
      onClick() {
        const index = value.indexOf(item.value);

        if (!item.value) {
          value = [];
        } else if (index > -1) {
          value.splice(index, 1);
        } else {
          value.push(item.value);
        }

        setForm({
          ...form || {},
          page: 1,
          [name]: value,
        });
      },
      sx: {
        color(theme) {
          if (!item.value) {
            return value.length > 0 ? theme.palette.grey[700] : theme.primary;
          }
          return value.indexOf(item.value) > -1 ? theme.primary : theme.palette.grey[700];
        },
      },
    };

    return (<Button {...btnProps}>{item.name}</Button>);
  });
};

export default ButtonsForm;
