import React, { useEffect, useState } from 'react';

import { Accordion } from '@mui/material';

import QueueItemSummary from './QueueItem/QueueItemSummary';
import QueueItemDetails from './QueueItem/QueueItemDetails';

const QueueItem = ({
  queue,
  dropQueue,
  expanded: expandedProp,
}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expandedProp);
  }, [expandedProp]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      elevation={0}
      sx={{ width: '100%' }}
    >
      <QueueItemSummary queue={queue} dropQueue={dropQueue} />
      <QueueItemDetails queue={queue} />
    </Accordion>
  );
};

export default QueueItem;
