import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { CloudDownload, FileUpload } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import StateContext from '../../HO/State/StateContext';

const ExportQueueButton = (props) => {
  const { t } = useContext(TranslationsContext);
  const stateContext = useContext(StateContext);
  const { jobResult, sendRequest } = stateContext;

  const { exportQueueRoute, form } = props;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [jobId, setJobId] = useState(null);

  const iconButtonProps = {
    title: t('app:common:export'),
    onClick: () => {
      setLoading(true);

      sendRequest(exportQueueRoute, {
        body: form,
      }).then((response) => {
        setJobId(response.data);

        jobResult(response.data, () => {
          setLoading(false);
          setProgress(null);
        }, (percent) => {
          setProgress(percent);
        });
      });
    },
    disabled: loading,
  };

  useEffect(() => {
    if (jobId) {
      setJobId(null);
    }
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  if (jobId && !loading) {
    return (
      <Link
        href={`/account/downloadReport/id/${jobId}/`}
        target="_blank"
        sx={{ m: 1 }}
      >
        <CloudDownload sx={{ fontSize: 32 }} />
      </Link>
    );
  }

  if (loading) {
    if (progress) {
      const progressWrapperState = {
        sx: {
          position: 'relative',
          display: 'inline-flex',
        },
      };
      const boxProps = {
        sx: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      };
      const typographyProps = {
        variant: 'caption',
        component: 'div',
        color: 'text.secondary',
      };

      return (
        <Box {...progressWrapperState}>
          <CircularProgress variant="determinate" value={progress} sx={{ m: 1 }} />
          <Box {...boxProps}>
            <Typography {...typographyProps}>
              {Math.round(progress)}
              %
            </Typography>
          </Box>
        </Box>
      );
    }

    return (<CircularProgress sx={{ m: 1 }} />);
  }

  return (
    <IconButton {...iconButtonProps}>
      <FileUpload />
    </IconButton>
  );
};

ExportQueueButton.propTypes = {
  exportQueueRoute: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

ExportQueueButton.defaultProps = {

};

export default ExportQueueButton;
