import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  TextField,
} from '@mui/material';
import { Search, ArrowDropDown } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import Popper from '../../Functional/Popper/Popper';
import TableToolbarExport from '../Toolbar/TableToolbarExport';

const SelectInputForm = (props) => {
  const { t } = useContext(TranslationsContext);
  const { form } = props;
  const { setForm } = props;

  const { selectName, selectValue } = props;
  const { inputName, inputValue } = props;
  const { options } = props;
  const { exportProps } = props;

  const ref = useRef(null);

  const [select, setSelect] = useState(selectValue);
  const [input, setInput] = useState(inputValue);

  const onClick = () => {
    setForm({
      ...form || {},
      page: 1,
      [selectName]: select,
      [inputName]: input,
    });
  };

  const formGroupProps = {
    row: true,
    sx: {
      flexWrap: 'nowrap',
      '& .MuiSelect-select': {
        bgcolor: 'background.paper',
      },
    },
  };
  const selectProps = {
    ref,
    variant: 'outlined',
    sx: {
      borderColor: 'rgba(0,0,0,0.23)',
      bgcolor: 'background.paper',
    },
  };
  const popperProps = {
    ref,
  };
  const boxProps = {
    sx: {
      bgcolor: 'background.paper',
    },
    component: 'ul',
  };

  const textProps = {
    label: (options.find((option) => option.value === select) || { name: '' }).name,
    sx: {
      flex: 1,
      '& .MuiOutlinedInput-input': {
        bgcolor: 'background.paper',
      },
    },
    value: input,
    onKeyPress(event) {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    onChange(event) {
      setInput(event.target.value);
    },
  };
  const buttonProps = {
    variant: 'contained',
    disableElevation: true,
    onClick,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Button {...selectProps}>
        <Search />
        <ArrowDropDown />
      </Button>
      <Popper {...popperProps}>
        <Box {...boxProps}>
          {options.map((option) => (
            <MenuItem
              key={v4()}
              selected={select === option.value}
              onClick={() => setSelect(option.value)}
              value={option.value}
            >
              {option.name}
            </MenuItem>
          ))}
        </Box>
      </Popper>

      <TextField {...textProps} />

      <Button {...buttonProps}>{t('app:common:search')}</Button>

      {exportProps ? (<TableToolbarExport {...exportProps} />) : null}
    </FormGroup>
  );
};

SelectInputForm.propTypes = {
  form: PropTypes.instanceOf(Object),
  setForm: PropTypes.func.isRequired,
};

SelectInputForm.defaultProps = {
  form: {},
};

export default SelectInputForm;
