import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import { FileUpload } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import StateContext from '../../HO/State/StateContext';

const ExportButton = (props) => {
  const { children } = props;
  const { data } = props;
  const { fileName } = props;

  const { sendRequest } = useContext(StateContext);
  const { t } = useContext(TranslationsContext);

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(['header']);

  const iconButtonProps = {
    title: t('app:common:export'),
    onClick() {
      setOpen(true);
    },
  };
  const dialogProps = {
    onClose() {
      setOpen(false);
    },
    open,
  };
  const cancelButtonProps = {
    onClick() {
      setOpen(false);
    },
  };
  const exportButtonProps = {
    onClick() {
      setOpen(false);

      const body = [];

      if (checked.indexOf('header') > -1) {
        const row = [];
        Object.keys(data[0]).forEach((key) => {
          if (checked.indexOf(key) > -1) {
            row.push(key);
          // } else if (data[0][key] && typeof data[0][key] === 'object') {
          //   Object.keys(data[0][key]).forEach((sKey) => {
          //     const n = `${key}.${sKey}`;
          //
          //     if (checked.indexOf(n) > -1) {
          //       row.push(n);
          //     }
          //   });
          }
        });
        body.push(row);
      }

      data.forEach((item) => {
        const row = [];
        Object.keys(item).forEach((key) => {
          if (checked.indexOf(key) > -1) {
            row.push(item[key] ? item[key].toString() : '');
          // } else if (item[key] && typeof item[key] === 'object') {
          //   Object.keys(item[key]).forEach((sKey) => {
          //     const n = `${key}.${sKey}`;
          //
          //     if (checked.indexOf(n) > -1) {
          //       row.push(item[key] ? item[key][sKey].toString() : '');
          //     }
          //   });
          }
        });
        body.push(row);
      });

      sendRequest('arrayToFile', {
        body,
      }).then((response) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response);
        a.setAttribute('download', fileName);
        a.click();
      });
    },
  };
  const onChange = (event) => {
    const newChecked = Object.assign([], checked);
    const index = newChecked.indexOf(event.target.value);
    if (!event.target.checked && index > -1) {
      newChecked.splice(index, 1);
    } else if (event.target.checked && index === -1) {
      newChecked.push(event.target.value);
    }
    setChecked(newChecked);
  };
  const checkboxHeaderProps = {
    control: (
      <Checkbox
        checked={checked.indexOf('header') > -1}
        value="header"
        onChange={onChange}
      />
    ),
    label: t('app:common:header'),
  };

  useEffect(() => {
    if (data && data[0]) {
      setChecked(['header'].concat(Object.keys(data[0]).map((label) => label)));
    }
  }, [data, setChecked]);

  if (typeof data !== 'object' && data.length) {
    return null;
  }

  return (
    <>
      <IconButton {...iconButtonProps}>{children || <FileUpload />}</IconButton>

      <Dialog {...dialogProps}>
        <DialogTitle>{t('app:common:actions:select-export')}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <FormGroup>
              <FormControlLabel {...checkboxHeaderProps} />
              {data[0] && Object.keys(data[0]).map((label) => {
                // if (data[0][label] && typeof data[0][label] === 'object') {
                //   return Object.keys(data[0][label]).map((l) => {
                //     if (typeof data[0][label][l] === 'object') {
                //       return null;
                //     }
                //
                //     const value = `${label}.${l}`;
                //     const checkboxProps = {
                //       control: (
                //         <Checkbox,
                //           checked={checked.indexOf(value) > -1}
                //           value={value}
                //           onChange={onChange}
                //         />
                //       ),
                //       label: value,
                //     };
                //
                //     return (<FormControlLabel {...checkboxProps} />);
                //   });
                // }

                const checkboxProps = {
                  key: v4(),
                  control: (
                    <Checkbox
                      checked={checked.indexOf(label) > -1}
                      value={label}
                      onChange={onChange}
                    />
                  ),
                  label,
                };

                return (<FormControlLabel {...checkboxProps} />);
              })}
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button {...cancelButtonProps}>{t('app:common:cancel')}</Button>
          <Button {...exportButtonProps}>{t('app:common:export')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ExportButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object),
  // children: PropTypes.any,
};

ExportButton.defaultProps = {
  // children: null,
  data: {},
};

export default ExportButton;
