import React from 'react';
import { v4 } from 'uuid';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const RadioForm = (props) => {
  const { form } = props;
  const { setForm } = props;

  const { name } = props;
  const { value } = props;
  const { label } = props;
  const { values } = props;

  const formControlProps = {
    sx: {},
    fullWidth: true,
  };
  const radioGroup = {
    name,
    value,
    onChange(event) {
      setForm({
        ...form || {},
        page: 1,
        [name]: event.target.value,
      });
    },
  };

  return (
    <FormControl {...formControlProps}>
      {label ? (<FormLabel>{label}</FormLabel>) : null}
      <RadioGroup {...radioGroup}>
        {values && values.map((val) => (
          <FormControlLabel
            {...val}
            key={v4()}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioForm;
