import { useContext } from 'react';

import TranslationsContext from '../../../../../components/Container/Language/TranslationsContext';

const SmsResultText = ({ text }) => {
  const { t } = useContext(TranslationsContext);

  return (text || '')
    .replace('Подготовка телефонов...Ожидайте', t('app:controller:sms:popup:waiting:preparation'))
    .replace('Проверка данных...Ожидайте', t('app:controller:sms:popup:waiting:check'))
    .replace('Подсчет стоимости рассылки...Ожидайте', t('app:controller:sms:popup:waiting:calc'))
    .replace('Формирование рассылки...', t('app:controller:sms:popup:waiting:form'))
    .replace('Сохранение рассылки...', t('app:controller:sms:popup:waiting:save'))
    .replace('Подготовка отчета...', t('app:controller:sms:popup:waiting:prepare'));
};

export default SmsResultText;
