import { Typography } from '@mui/material';
import React, { useContext } from 'react';

import TranslationsContext from '../../../../../components/Container/Language';

const SmsResultErrors = ({ errors }) => {
  const { t } = useContext(TranslationsContext);

  return (
    <>
      { /* viber */ }
      {errors.indexOf('ERROR_VIBER') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:viber')}</Typography>
      )}
      {errors.indexOf('ERROR_VIBER_MESSAGE_REQUIRED') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:viber:message-required')}</Typography>
      )}
      {errors.indexOf('ERROR_VIBER_MESSAGE') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:viber:message')}</Typography>
      )}
      {errors.indexOf('ERROR_VIBER_MESSAGE_LENGTH') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:viber:message-length')}</Typography>
      )}
      {errors.indexOf('ERROR_VIBER_ALPHANAME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:viber:alphaname')}</Typography>
      )}
      {errors.indexOf('ERROR_VIBER_LIFETIME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:viber:lifetime')}</Typography>
      )}
      { /* rcs */ }
      {errors.indexOf('ERROR_RCS') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs')}</Typography>
      )}
      {errors.indexOf('ERROR_RCS_MESSAGE_REQUIRED') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs:message-required')}</Typography>
      )}
      {errors.indexOf('ERROR_RCS_MESSAGE') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs:message')}</Typography>
      )}
      {errors.indexOf('ERROR_RCS_MESSAGE_LENGTH') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs:message-length')}</Typography>
      )}
      {errors.indexOf('ERROR_RCS_ALPHANAME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs:alphaname')}</Typography>
      )}
      {errors.indexOf('ERROR_RCS_LIFETIME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs:lifetime')}</Typography>
      )}
      {errors.indexOf('ERROR_RCS_OPERATOR') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:rcs:operator')}</Typography>
      )}
      { /* viber | rcs */ }
      {(errors.indexOf('ERROR_VIBER_IMAGE_NOT_FOUND') > -1 || errors.indexOf('ERROR_RCS_IMAGE_NOT_FOUND') > -1) && (
        <Typography color="error">{t('app:controller:sms:popup:error:image')}</Typography>
      )}
      {(errors.indexOf('ERROR_VIBER_IMAGE_REQUIRED') > -1 || errors.indexOf('ERROR_RCS_IMAGE_REQUIRED') > -1) && (
        <Typography color="error">{t('app:controller:sms:popup:error:image')}</Typography>
      )}
      {(errors.indexOf('ERROR_VIBER_URL_FORMAT') > -1 || errors.indexOf('ERROR_RCS_URL_FORMAT') > -1) && (
        <Typography color="error">{t('app:controller:sms:popup:error:url')}</Typography>
      )}
      {(errors.indexOf('ERROR_VIBER_URL_REQUIRED') > -1 || errors.indexOf('ERROR_RCS_URL_REQUIRED') > -1) && (
        <Typography color="error">{t('app:controller:sms:popup:error:url')}</Typography>
      )}
      {(errors.indexOf('ERROR_VIBER_BUTTON') > -1 || errors.indexOf('ERROR_RCS_BUTTON') > -1) && (
        <Typography color="error">{t('app:controller:sms:popup:error:button')}</Typography>
      )}

      {errors.indexOf('ERROR_ACCESS_DENIED') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:access-denied')}</Typography>
      )}
      {errors.indexOf('ERROR_ADDRESSBOOK_REQUIRED') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:recipients')}</Typography>
      )}
      {errors.indexOf('ERROR_ADDRESSBOOK_NOT_FOUND') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:recipients-exist')}</Typography>
      )}
      {errors.indexOf('ERROR_ALPHANAME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:alphaname')}</Typography>
      )}
      {errors.indexOf('ERROR_ALPHANAME_FORMAT') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:alphaname-format')}</Typography>
      )}
      {errors.indexOf('ERROR_ALPHANAME_LENGTH') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:alphaname-length')}</Typography>
      )}
      {errors.indexOf('ERROR_BALANCE') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:balance')}</Typography>
      )}
      {errors.indexOf('ERROR_CAMPAIGN_NAME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:campaign-name')}</Typography>
      )}
      {errors.indexOf('ERROR_FLASH_CONCATENATED') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:flash-concatenated')}</Typography>
      )}
      {errors.indexOf('ERROR_MESSAGE') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:message')}</Typography>
      )}
      {errors.indexOf('ERROR_MESSAGE_EMPTY') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:message-empty')}</Typography>
      )}
      {errors.indexOf('ERROR_MESSAGE_LENGTH') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:message-length')}</Typography>
      )}
      {errors.indexOf('ERROR_PHONE') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:phone')}</Typography>
      )}
      {errors.indexOf('ERROR_PHONELIST') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:phonelist')}</Typography>
      )}
      {errors.indexOf('ERROR_UNKNOWN') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:unknown')}</Typography>
      )}
      {errors.indexOf('ERROR_EMPTY') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:phone-exist')}</Typography>
      )}
      {errors.indexOf('ERROR_START') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:start')}</Typography>
      )}
      { /* sms */ }
      {errors.indexOf('ERROR_SMS_ALPHANAME') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:alphaname')}</Typography>
      )}
      {errors.indexOf('ERROR_SMS_MESSAGE') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:message')}</Typography>
      )}
      {errors.indexOf('ERROR_SMS_MESSAGE_LENGTH') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:message-length')}</Typography>
      )}
      {errors.indexOf('ERROR_SMS_MESSAGE_REQUIRED') > -1 && (
        <Typography color="error">{t('app:controller:sms:popup:error:phonelist')}</Typography>
      )}
    </>
  );
};

export default SmsResultErrors;
