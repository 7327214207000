import React from 'react';

import { AccordionDetails } from '@mui/material';

import SmsResult from '../../../../../routes/App/Sms/Components/SmsResultDialog/SmsResult';

const QueueItemDetails = ({ queue }) => (
  <AccordionDetails>
    {queue.type === 'campaign' && (<SmsResult {...queue} />)}
    {queue.type !== 'campaign' && (queue.text)}
  </AccordionDetails>
);

export default QueueItemDetails;
