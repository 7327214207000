import LanguagesProvider from './LanguagesProvider';
import TranslationsProvider from './TranslationsProvider';
import TranslationsContext from './TranslationsContext';

export { LanguagesProvider, TranslationsProvider };

export { Consumer as LanguagesConsumer } from './LanguagesContext';
export { Consumer as TranslationsConsumer } from './TranslationsContext';

export default TranslationsContext;
