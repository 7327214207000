import React, { forwardRef, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

import {
  Box,
  ClickAwayListener,
  Grow,
  Paper,
  Popper as MUIPopper,
} from '@mui/material';

const Popper = forwardRef((props, ref) => {
  const { children } = props;
  const [open, setOpen] = useState(false);

  const popperProps = {
    open,
    anchorEl: ref.current,
    transition: true,
    disablePortal: true,
    style: {
      zIndex: 1,
    },
  };

  useEffect(() => {
    const listener = () => setOpen(true);

    if (ref && ref.current) {
      ref.current.addEventListener('click', listener);
    }

    return () => {
      if (ref && ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener('click', listener);
      }
    };
  }, [ref, setOpen]);

  if (!ref || !ref.current) {
    return null;
  }

  return (
    <MUIPopper {...popperProps}>
      {(value) => {
        const { TransitionProps } = value;
        const { placement } = value;
        const growProps = {
          ...TransitionProps || {},
          style: {
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          },
        };
        const cALProps = {
          onClickAway() {
            setOpen(false);
          },
        };

        return (
          <Grow {...growProps}>
            <Paper>
              <ClickAwayListener {...cALProps}>
                <Box>{children}</Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        );
      }}
    </MUIPopper>
  );
});

Popper.propTypes = {
  // children: PropTypes.any,
};

Popper.defaultProps = {
  children: '',
};

export default Popper;
