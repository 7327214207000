import React, { useState } from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';

const InputForm = (props) => {
  const { form, setForm } = props;

  const {
    values, name, label, helperText, loading, type,
  } = props;
  const { value: valueProps } = props;
  // events
  const { onOpen, onChange } = props;

  const { btnIcon } = props;
  const { btnDisabled } = props;
  const { inputError } = props;

  const [value, setValue] = useState(valueProps || '');

  const onClick = () => {
    setForm({
      ...(form || {}),
      page: 1,
      [name]: value,
    });
  };

  const formControlProps = {
    sx: {},
    fullWidth: true,
  };
  const fieldProps = {
    fullWidth: true,
    sx: {
      pr: 0,
      bgcolor: 'background.paper',
    },
    value: type === 'autocomplete' ? (_.find(values, (option) => (option.value || option.id || option.name) === value) || { name: value }) : value,
  };
  const inputLabelProps = {};
  if (['autocomplete'].indexOf(type) === -1) {
    fieldProps.label = label;
    fieldProps.error = !!inputError;
    fieldProps.type = type || 'text';
  }

  if (['datetime-local', 'time', 'date'].indexOf(type) > -1) {
    inputLabelProps.shrink = true;
    fieldProps.onChange = (event) => {
      setValue(event.target.value);
      // onClick();
      setForm({
        ...(form || {}),
        page: 1,
        [name]: event.target.value,
      });
    };
  } else if (values && typeof values === 'object') {
    fieldProps.onChange = (event, newValue) => {
      setValue(newValue?.value || newValue?.id || newValue?.name || '');
      // onClick();
      setForm({
        ...(form || {}),
        page: 1,
        [name]: type === 'autocomplete' ? (newValue?.value || newValue?.id || newValue?.name || '') : event.target.value,
      });
    };
    if (typeof onOpen === 'function') {
      fieldProps.onOpen = onOpen;
    }
  } else {
    fieldProps.endAdornment = (
      <InputAdornment position="end">
        <Button variant="text" onClick={onClick} disabled={!!btnDisabled}>{btnIcon || <Search />}</Button>
      </InputAdornment>
    );
    fieldProps.onKeyPress = (event) => {
      if (event.key === 'Enter') {
        onClick();
      }
    };
    fieldProps.onChange = (event) => {
      if (typeof onChange === 'function') {
        onChange(event);
      }
      setValue(event.target.value);
    };
  }

  if (type === 'autocomplete') {
    fieldProps.options = values || [];
    fieldProps.getOptionLabel = (option) => option.name;
    fieldProps.disablePortal = true;

    fieldProps.renderInput = (params) => {
      const textFieldProps = { ...params, error: !!inputError, label };
      if (loading) {
        textFieldProps.InputProps = {
          ...params.InputProps,
          endAdornment: (
            <>
              <CircularProgress size={20} />
              {params.InputProps.endAdornment}
            </>
          ),
        };
      }
      return (<TextField {...textFieldProps} />);
    };

    return (<Autocomplete {...fieldProps} />);
  }

  return (
    <FormControl {...formControlProps}>
      <InputLabel {...inputLabelProps}>{fieldProps.label}</InputLabel>

      {values && typeof values === 'object' ? (
        <Select {...fieldProps}>
          {values.map((item) => (
            <MenuItem key={v4()} value={item.value || item.id}>{item.name}</MenuItem>
          ))}
        </Select>
      ) : (<OutlinedInput {...fieldProps} />)}

      {typeof helperText === 'string' && (<FormHelperText>{helperText}</FormHelperText>)}
    </FormControl>
  );
};

export default InputForm;
