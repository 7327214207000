import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Provider } from './QueueContext';
import StateContext from '../../../HO/State/StateContext';

const QueueProvider = ({ children }) => {
  const { sendRequest } = useContext(StateContext);
  const [queues, setQueues] = useState([]);
  const isInitialMount = useRef(true);
  const jobsResultTimer = useRef(null);
  const jobsWorking = useRef(false);

  const addQueue = (id, params) => {
    setQueues((value) => [...value, {
      id,
      percent: 0,
      started: new Date(),
      ...(params || {}),
    }]);
  };

  // const updateQueue = (id, percent, text) => setQueues(
  //   (value) => _.map(value, (queue) => (queue.id === id ? ({
  //     ...queue,
  //     percent: Number(percent) || 0,
  //     text,
  //   }) : queue)),
  // );

  const dropQueue = (id) => setQueues((value) => _.filter(value, (queue) => (queue.id !== id)));

  const getQueue = (id) => _.find(queues, (queue) => queue.id === id);

  useEffect(() => {
    if (isInitialMount.current) { // only one time
      try {
        const qs = JSON.parse(window.localStorage.getItem('_queues') || '[]') || [];
        if (qs.length) {
          setQueues(qs);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      window.localStorage.setItem('_queues', JSON.stringify(queues));
    }
  }, [queues]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const jobsResult = () => {
        jobsWorking.current = true;
        clearTimeout(jobsResultTimer.current);

        const jobs = _.filter(_.map(queues, (queue) => (
          queue.finished ? null : queue.id
        )));
        // console.log(queues, jobs);
        if (jobs.length) {
          return sendRequest('jobsResult', {
            body: { jobs },
          }).then((response) => {
            if (response && response.success && response.data) {
              setQueues(_.filter(_.map(queues, (queue) => {
                if (!(queue.id in response.data) && queue.finished) {
                  return queue;
                }

                const {
                  status_id: statusId,
                  percent,
                  status_text: text,
                  result,
                } = response.data[queue.id] || {};

                if (Number(statusId) === 4) { // success
                  if (typeof queue.successFunc === 'function' && result && !result.error && !(result.errors || []).length) {
                    queue.successFunc();
                  }

                  return {
                    ...queue,
                    percent: 100,
                    finished: new Date(),
                    result,
                  };
                }
                if (Number(statusId) === 3) { // error
                  return {
                    ...queue,
                    percent: 100,
                    finished: new Date(),
                    text: 'Unknown Error',
                  };
                }
                if (Number(statusId) === 2) { // in process
                  return {
                    ...queue,
                    percent: Number(percent) || 0,
                    text: (text || ''),
                  };
                }

                return null;
              })));

              jobsResultTimer.current = setTimeout(() => {
                jobsWorking.current = false;
                setQueues((q) => [...q]); // call hook
              }, 5000);
            } else {
              setQueues([]);
            }
          });
        }

        jobsWorking.current = false;

        return Promise.resolve({}); // not started
      };

      if (!jobsWorking.current) {
        try {
          jobsResult();
        } catch (e) {
          console.log(e);
        }
      }
    }

    // return () => { // delete completed
    //   try {
    //     const array = JSON.parse(window.localStorage.getItem('_queues'));
    //     const newQueues = _.filter(array, ({ finished }) => !!finished);
    //
    //     window.localStorage.setItem('_queues', JSON.stringify(newQueues));
    //   } catch (e) {
    //     console.log(e);
    //   }
    // };
  }, [sendRequest, queues]);

  return (
    <Provider
      value={{
        queues,
        addQueue,
        dropQueue,
        getQueue,
      }}
    >
      {children}
    </Provider>
  );
};

QueueProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

QueueProvider.defaultProps = {

};

export default QueueProvider;
