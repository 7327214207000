import React, { useEffect } from 'react';

import { IconButton } from '@mui/material';
import { PowerSettingsNewRounded } from '@mui/icons-material';

import { FormConsumer, FormProvider } from '../../../HO/Form';

const LogOut = ({
  sendForm, formData, formErrors, dataLoading,
}) => {
  useEffect(() => {
    if (formErrors) {
      //
    } else if (formData) {
      if (window.localStorage) {
        window.localStorage.removeItem('_queues');
      }
      window.location.assign('/');
    }
  }, [formErrors, formData]);

  return (
    <IconButton disabled={dataLoading} onClick={() => sendForm()}>
      <PowerSettingsNewRounded />
    </IconButton>
  );
};

const LogOutContext = (props) => (
  <FormProvider formName="logout">
    <FormConsumer>
      {(state) => (<LogOut {...props} {...state} />)}
    </FormConsumer>
  </FormProvider>
);

export default LogOutContext;
