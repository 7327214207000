import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';

const TableToolbarDelete = (props) => {
  const { t } = useContext(TranslationsContext);

  const [open, setOpen] = useState(false);

  const { sendForm } = props;
  const { formLoading } = props;
  const { formErrors } = props;
  const { formData } = props;
  const { body } = props;
  const { getData } = props;

  const tooltipProps = {
    title: t('app:common:actions:remove'),
  };
  const deleteProps = {
    disabled: formLoading,
    onClick() {
      setOpen(true);
    },
  };
  const dialogProps = {
    open,
    onClose() {
      setOpen(false);
    },
    'aria-labelledby': 'alert-confirm-delete-dialog-title',
  };
  const dialogTitleProps = {
    id: 'alert-confirm-delete-dialog-title',
  };
  const dialogActionsProps = {

  };
  const dialogAgreeProps = {
    disabled: formLoading,
    onClick() {
      setOpen(false);
      sendForm(body);
    },
    // autoFocus: true
  };
  const dialogDisagreeProps = {
    disabled: formLoading,
    onClick() {
      setOpen(false);
    },
  };

  useEffect(() => {
    if (formErrors) {
      //
    } else if (formData) {
      getData();
    }
  }, [getData, formErrors, formData]);

  return (
    <>
      <Tooltip {...tooltipProps}>
        <Box>
          <IconButton {...deleteProps}><Delete /></IconButton>
        </Box>
      </Tooltip>

      <Dialog {...dialogProps}>
        <DialogTitle {...dialogTitleProps}>{t('app:confirm:deletion')}</DialogTitle>
        <DialogActions {...dialogActionsProps}>
          <Button {...dialogDisagreeProps}>{t('app:common:no')}</Button>
          <Button {...dialogAgreeProps}>{t('app:common:yes')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TableToolbarDelete.propTypes = {
  sendForm: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formErrors: PropTypes.instanceOf(Object),
  formData: PropTypes.instanceOf(Object),
  body: PropTypes.instanceOf(Object),
  getData: PropTypes.func,
};

TableToolbarDelete.defaultProps = {
  formLoading: false,
  formErrors: [],
  formData: [],
  body: {},
  getData() {},
};

export default TableToolbarDelete;
