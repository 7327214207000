import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';

import {
  Alert,
  TableCell,
  TableBody as MUITableBody,
  TableRow as MUITableRow,
} from '@mui/material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import TableRow from './TableRow';

const TableBody = (props) => {
  const { t } = useContext(TranslationsContext);

  const { selected, rows, keys } = props;
  const { showCheckbox, onRowClick, useDnd } = props;

  if (rows.length === 0) {
    const tableCellProps = {
      colSpan: keys.length + (showCheckbox ? 1 : 0),
      align: 'center',
      style: {
        padding: 0,
      },
    };
    const alertProps = {
      severity: 'error',
    };

    return (
      <MUITableRow>
        <TableCell {...tableCellProps}>
          <Alert {...alertProps}>{t('app:common:empty')}</Alert>
        </TableCell>
      </MUITableRow>
    );
  }

  return rows.map((row, index) => {
    const tableRowProps = {
      hover: true,
      role: 'checkbox',
      'aria-checked': selected.indexOf(row.id) > -1,
      tabIndex: -1,
      selected: selected.indexOf(row.id) > -1,
      sx: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#f7f7f7', // theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      },
    };
    if (typeof onRowClick === 'function' && row.id) {
      tableRowProps.onClick = (event) => {
        if (['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT', 'A'].indexOf(event.target.tagName.toUpperCase()) < 0) {
          onRowClick(row, event);
        }
      };
      tableRowProps.sx['&:hover'] = {
        cursor: 'pointer',
      };
      tableRowProps.sx['&:hover td'] = {
        // borderTop: 1,
        // borderBottom: 1,
        // borderColor: '#aaa',
        backgroundColor: '#eee',
      };
    }

    if (useDnd) {
      return (
        <Draggable draggableId={`${row.id}`} index={index} key={`${row.id}-0`} type="list">
          {(provided, snapshot) => (
            <MUITableRow
              sx={{ opacity: snapshot.isDragging ? 0.8 : 1 }}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...tableRowProps}
            >
              <TableRow {...props} row={row} dragHandle={provided.dragHandleProps} />
            </MUITableRow>
          )}
        </Draggable>
      );
    }

    return (
      <MUITableRow {...tableRowProps} key={row.id || v4()}>
        <TableRow {...props} row={row} />
      </MUITableRow>
    );
  });
};

TableBody.propTypes = {
  rows: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func,
  keys: PropTypes.instanceOf(Array),
  // exclude: PropTypes.instanceOf(Array),
  showCheckbox: PropTypes.bool,
};

TableBody.defaultProps = {
  rows: [],
  onRowClick: null,
  keys: [],
  showCheckbox: false,
};

const SortableTableBody = ({ useDnd, tableBodyChildren, ...props }) => (useDnd ? (
  <DragDropContext onDragEnd={props.onDragEnd}>
    <Droppable droppableId="0" type="list">
      {(provided) => (
        <MUITableBody
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {tableBodyChildren || (<TableBody {...props} useDnd />)}
          {provided.placeholder}
        </MUITableBody>
      )}
    </Droppable>
  </DragDropContext>
) : (
  <MUITableBody>
    {tableBodyChildren || (<TableBody {...props} />)}
  </MUITableBody>
));

export default SortableTableBody;
