import React from 'react';

import ExportButton from '../Button/ExportButton';
import ExportRouteButton from '../Button/ExportRouteButton';
import ExportQueueButton from '../Button/ExportQueueButton';

const TableToolbarExport = (props) => {
  const { exportData } = props;
  const { children } = props;
  const { exportQueueRoute } = props;
  const { exportRoute } = props;
  const { form } = props;
  const { total } = props;

  const today = new Date();
  const str = `${today.getFullYear()}${today.getMonth()}${today.getDate()}${today.getHours()}${today.getMinutes()}`;
  const exportButtonProps = {
    data: exportData,
    fileName: `${children} ${str}.xls`.trim().replace(/\s/g, '-'),
    exportQueueRoute,
    exportRoute,
    form,
  };

  if (total) {
    if (exportData) {
      return (<ExportButton {...exportButtonProps} />);
    }
    if (exportRoute) {
      return (<ExportRouteButton {...exportButtonProps} />);
    }
    if (exportQueueRoute) {
      return (<ExportQueueButton {...exportButtonProps} />);
    }
  }

  return null;
};

export default TableToolbarExport;
