import React from 'react';

import QueueContext from './QueueContext';
import QueueProvider from './QueueProvider';
import QueueProgress from './QueueProgress';

const SendProgressWrapper = ({ children }) => (
  <QueueProvider>
    <QueueProgress />

    {children}
  </QueueProvider>
);

export default SendProgressWrapper;
export { QueueContext };
