import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Table as MUITable,
  TableContainer,
  TableHead as MUITableHead,
  TablePagination,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import TablePaginationActions from './TablePaginationActions';
import { FormConsumer, FormProvider } from '../../HO/Form';
import TableImport from './TableImport';
import LoadingData from '../../Functional/Progress/LoadingData';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableToolbar from '../Toolbar/TableToolbar';
import TableForm from './TableForm';

const Table = (props) => {
  const { t } = useContext(TranslationsContext);
  const theme = useTheme();
  const isDownThanMD = useMediaQuery(theme.breakpoints.down('md'));
  const isDownThanSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { disableToolbar } = props;
  const { head } = props;
  const { rows: rowsProps } = props;
  const rows = rowsProps || []; // eslint-disable-line react-hooks/exhaustive-deps
  const { total } = props; // || rows.length;
  const { onClick } = props;
  const { form } = props;
  const { setForm } = props;
  const { getData } = props;
  const { onChange } = props;
  const { keys } = props;
  const { title } = props;
  const { size } = props;
  const { textFields } = props;
  const { multiDeleteRoute, onSortable } = props;
  const { exportData } = props;
  const { exportQueueRoute, exportRoute } = props;
  const { importRoute, importCallback } = props;
  const { onCreate } = props;
  const { tableBodyChildren, rowsPerPageOptions } = props;
  const { loading } = props;
  const { containerOverflow } = props;

  const [importData, setImportData] = useState(null);
  const selectedState = useState([]);
  // eslint-disable-next-line react/destructuring-assignment
  const selected = typeof props.setSelected === 'function' ? props.selected : selectedState[0];
  // eslint-disable-next-line react/destructuring-assignment
  const setSelected = typeof props.setSelected === 'function' ? props.setSelected : selectedState[1];
  const [order, setOrder] = useState(form.order || 'desc');
  const [orderBy, setOrderBy] = useState(form.orderBy || 'ctime');
  const [page, setPage] = useState((form.page || 1) - 1);
  const [perPage, setPerPage] = useState(form.perPage || 10);

  // const key = props.id || '';
  const tableToolbarProps = {
    onCreate,
    form,
    getData,
    selected,
    multiDeleteRoute,
    total,
    exportData,
    exportQueueRoute,
    exportRoute,
    importRoute,
    setImportData,
  };
  const tableFormProps = {
    form,
    setForm,
    textFields,
  };
  const tableContainerProps = {
    component: Paper,
    style: containerOverflow ? { overflow: containerOverflow } : {},
  };

  const tableHeadProps = {
    order,
    orderBy,
    onRequestSort(property) {
      const newOrder = orderBy === property && order === 'asc' ? 'desc' : 'asc';

      setOrder(newOrder);
      setOrderBy(property);

      onChange({
        order: newOrder,
        orderBy: property,
      });
    },
    onSelectAllClick(event) {
      if (event.target.checked) {
        setSelected(rows.map((n) => n.id));
      } else {
        setSelected([]);
      }
    },
    head,
    numSelected: selected.length,
    rowCount: rows.length,
    showCheckbox: !!multiDeleteRoute && total > 0,
    onSortable,
  };
  const tableBodyProps = {
    rows,
    selected,
    onRowClick: onClick,
    setSelected,
    tableBodyChildren,
    onDragEnd: (result) => {
      const { destination, draggableId } = result;
      // nothing to do
      if (!destination || result.reason === 'CANCEL') {
        return;
      }

      onSortable(draggableId, destination.index, result);
    },
    useDnd: typeof onSortable === 'function' && rows.length > 0,
    keys,
    showCheckbox: !!multiDeleteRoute,
  };
  const tablePaginationProps = {
    labelRowsPerPage: t('app:common:paginator:per-page'),
    labelDisplayedRows(value) {
      return `${value.from}-${value.to} ${t('app:common:paginator:of')} ${value.count !== -1 ? value.count : `more than ${value.to}`}`;
    },
    rowsPerPageOptions: rowsPerPageOptions || [10, 20, 50, 100],
    component: 'div',
    count: total,
    rowsPerPage: perPage,
    page,
    onPageChange(event, newPage) {
      setPage(newPage);
      onChange({
        page: newPage + 1,
      });
    },
    onRowsPerPageChange(event) {
      const newPerPage = parseInt(event.target.value, 10);
      setPerPage(newPerPage);
      setPage(0);
      onChange({
        perPage: newPerPage,
        page: 1,
      });
    },
    sx: {
      '& .MuiTablePagination-selectLabel': isDownThanMD ? { display: 'none' } : {},
      '& .MuiToolbar-root': isDownThanSM ? { display: 'block' } : {},
      '& .MuiTablePagination-displayedRows': isDownThanSM ? { display: 'inline-block' } : {},
    },
    ActionsComponent: TablePaginationActions,
  };

  useEffect(() => {
    setSelected([]);
  }, [setSelected, rows]);

  if (importData) {
    const formProps = {
      formName: importRoute,
    };

    return (
      <FormProvider {...formProps}>
        <FormConsumer>
          {(value) => (
            <TableImport
              keys={keys}
              importCallback={(result) => {
                if (typeof importCallback === 'function' && result) {
                  importCallback(result);
                }
                getData();
                setImportData(null);
              }}
              importData={importData}
              title={title}
              {...value}
            />
          )}
        </FormConsumer>
      </FormProvider>
    );
  }

  return (
    <>
      {disableToolbar ? null : (<TableToolbar {...tableToolbarProps}>{title}</TableToolbar>)}

      {textFields && !!textFields.length ? (<TableForm {...tableFormProps} />) : null}

      {loading ? (<LoadingData />) : (
        <TableContainer {...tableContainerProps}>
          <MUITable size={size}>
            <MUITableHead><TableHead {...tableHeadProps} /></MUITableHead>
            <TableBody {...tableBodyProps} />
          </MUITable>
        </TableContainer>
      )}

      {!loading && total > rows.length ? (<TablePagination {...tablePaginationProps} />) : null}
    </>
  );
};

Table.propTypes = {
  // title: PropTypes.any,
  total: PropTypes.number,
  size: PropTypes.string,
  multiDeleteRoute: PropTypes.string,
  exportData: PropTypes.instanceOf(Array),
  importRoute: PropTypes.string,
  importCallback: PropTypes.func,
  form: PropTypes.instanceOf(Object),
  head: PropTypes.instanceOf(Array),
  rows: PropTypes.instanceOf(Array),
  keys: PropTypes.instanceOf(Array),
  textFields: PropTypes.instanceOf(Array),
  setForm: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
};

Table.defaultProps = {
  // title: '',
  size: 'medium',
  multiDeleteRoute: '',
  exportData: null,
  importRoute: '',
  importCallback() {},
  total: null,
  form: {},
  head: [],
  rows: [],
  keys: [],
  textFields: [],
  setForm() {},
  onClick: null,
  onChange() {},
  // getData() {},
  onCreate: null,
};

export default Table;
