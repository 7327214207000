import React from 'react';
import { v4 } from 'uuid';

import { Checkbox, TableCell } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';

const TableRow = (props) => {
  const { row, keys, selected } = props;
  const { setSelected, showCheckbox, dragHandle } = props;

  let totalCell = 0;
  const tableCheckboxCellProps = {
    padding: 'checkbox',
  };

  const checkboxProps = {
    color: 'primary',
    checked: selected.indexOf(row.id) > -1,
    onChange() {
      const newSelected = Object.assign([], selected);
      const i = newSelected.indexOf(row.id);
      if (i > -1) {
        newSelected.splice(i, 1);
      } else {
        newSelected.push(row.id);
      }
      setSelected(newSelected);
    },
    inputProps: {
      // 'aria-labelledby': labelId,
    },
    sx: {
      p: 0,
    },
  };

  return (
    <>
      {showCheckbox ? (
        <TableCell {...tableCheckboxCellProps}>
          {row.id ? (<Checkbox {...checkboxProps} />) : ''}
        </TableCell>
      ) : null}

      {keys.map((key) => {
        let cell = row[key] || '';
        let tableCellProps = {
          key: `cell-${row.id || v4()}-${key}`,
          align: 'left',
        };
        if (Array.isArray(row[key])) {
          cell = row[key][0]; // eslint-disable-line prefer-destructuring
          tableCellProps = { ...tableCellProps, ...row[key][1] };
        }
        totalCell += (tableCellProps.colSpan || 1);

        if (totalCell > keys.length) {
          return null; // fix table
        }

        return (<TableCell {...tableCellProps}>{cell}</TableCell>);
      })}

      {dragHandle ? (
        <TableCell {...dragHandle}><DragIndicator /></TableCell>
      ) : null}
    </>
  );
};

export default TableRow;
