import React from 'react';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const CheckboxForm = (props) => {
  const { form } = props;
  const { setForm } = props;

  const { name } = props;
  const { value } = props;
  const { label } = props;

  const checkboxProps = {
    checked: value,
    onChange(event) {
      setForm({
        ...form || {},
        page: 1,
        [name]: event.target.checked,
      });
    },
    inputProps: {
      'aria-label': 'controlled',
    },
  };

  const formControlLabelProps = {
    control: (<Checkbox {...checkboxProps} />),
    label,
  };

  return (
    <FormGroup>
      <FormControlLabel {...formControlLabelProps} />
    </FormGroup>
  );
};

export default CheckboxForm;
