import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Grid } from '@mui/material';

import RadioForm from '../Form/RadioForm';
import SelectInputForm from '../Form/SelectInputForm';
import TabsForm from '../Form/TabsForm';
import ButtonsForm from '../Form/ButtonsForm';
import CheckboxForm from '../Form/CheckboxForm';
import InputForm from '../Form/InputForm';

const TableForm = (props) => {
  const { textFields } = props;
  const { setForm } = props;
  const { form } = props;

  if (!textFields.length) {
    return null;
  }

  const filterFields = _.filter(textFields, (item) => item.type !== 'tabs' && item.type !== 'buttons');

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      {textFields.map((textField, index) => {
        const gridProps = {
          key: `table-form-grid-${textField.id || textField.name || textField.type}-${index}`,
          item: true,
          xs: 12,
          sm: 12 / Math.min(filterFields.length, 2),
          md: 12 / Math.min(filterFields.length, 3),
          lg: 12 / Math.min(filterFields.length, 4),
          // xl: 12 / Math.min(textFields.length, 6),
          sx: {},
        };

        const textFieldProps = {
          ...textField,
          form,
          setForm,
        };

        if (textField.type === 'radio') {
          return (
            <Grid {...gridProps}><RadioForm {...textFieldProps} /></Grid>
          );
        }

        if (textField.type === 'checkbox') {
          return (
            <Grid {...gridProps}><CheckboxForm {...textFieldProps} /></Grid>
          );
        }

        if (textField.type === 'select-input') {
          return (
            <Grid {...gridProps}><SelectInputForm {...textFieldProps} /></Grid>
          );
        }

        if (textField.type === 'tabs') {
          return (
            <Grid key={gridProps.key} item xs={12}>
              <TabsForm {...textFieldProps} />
            </Grid>
          );
        }

        if (textField.type === 'buttons') {
          return (
            <Grid key={gridProps.key} item xs={12}>
              <ButtonsForm {...textFieldProps} />
            </Grid>
          );
        }

        if (textField.type === 'range-date') {
          const fromProps = {
            ...textField,
            type: 'date',
            name: textField.fromName,
            label: textField.fromLabel,
            value: textField.fromValue,

            form,
            setForm,
          };
          const toProps = {
            ...textField,
            type: 'date',
            name: textField.toName,
            label: textField.toLabel,
            value: textField.toValue,

            form,
            setForm,
          };
          gridProps.sx.display = 'flex';

          return (
            <Grid {...gridProps}>
              <InputForm {...fromProps} />
              <InputForm {...toProps} />
            </Grid>
          );
        }

        return (
          <Grid {...gridProps}>
            <InputForm {...textFieldProps} />
          </Grid>
        );
      })}
    </Grid>
  );
};

TableForm.propTypes = {
  textFields: PropTypes.instanceOf(Array).isRequired,
  setForm: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object),
};

TableForm.defaultProps = {
  form: {},
};

export default TableForm;
