import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';

const TableImport = (props) => {
  const { t } = useContext(TranslationsContext);

  const [options, setOptions] = useState({});

  const { keys } = props;
  const { importCallback } = props;
  const { importData } = props;
  // const { title } = props;

  const { formErrors } = props;
  const { formData } = props;
  const { sendForm } = props;
  const { formLoading } = props;

  const toolbarProps = {
    sx: {
      pl: {
        sm: 0,
      },
      pr: {
        xs: 1,
        sm: 1,
      },
    },
  };
  const titleProps = {
    sx: {
      flex: '1 1 100%',
    },
    variant: 'h6',
    component: 'div',
  };
  const buttonProps = {
    onClick() {
      importCallback(null);
    },
    variant: 'contained',
    startIcon: <KeyboardArrowLeft />,
    size: 'large',
    sx: {
      mr: 2,
    },
  };
  const divider1Props = {

  };
  const buttonSaveProps = {
    variant: 'contained',
    disabled: formLoading,
    onClick: () => sendForm(_.map(importData, (row) => _.mapValues(options, (option) => row[option] || ''))),
    sx: {
      mt: 2,
    },
  };

  const getValue = (num) => _.findKey(options, (val) => num === val) || '';
  const onChange = (value, num) => {
    if (value) {
      const newOptions = { ...options };
      const key = _.findKey(options, (val) => num === val);
      if (typeof key !== 'undefined') {
        newOptions[key] = null;
      }

      newOptions[value] = num;
      setOptions(newOptions);
    }
  };

  useEffect(() => {
    if (formErrors) {
      //
    } else if (formData) {
      importCallback(formData);
    }
  }, [formErrors, formData, importCallback]);

  return (
    <>
      <Toolbar {...toolbarProps}>
        <Button {...buttonProps}>{t('app:common:back')}</Button>
        <Typography {...titleProps} />
      </Toolbar>

      <Divider {...divider1Props} />

      <TableContainer>
        <Table>
          {importData[0] && (
            <TableHead>
              <TableRow>
                {_.range(0, importData[0].length).map((i) => (
                  <TableCell key={`table-import-table-cell-${i}`}>
                    <FormControl fullWidth>
                      <InputLabel>Select</InputLabel>
                      <Select
                        onChange={(el) => onChange(el.target.value, i)}
                        label="Select"
                        value={getValue(i)}
                        sx={{ bgcolor: 'background.paper' }}
                      >
                        <MenuItem value="select">Select</MenuItem>
                        {keys.indexOf('id') === -1 && (<MenuItem value="id">ID</MenuItem>)}

                        {keys.map((key) => (
                          <MenuItem key={`table-import-table-cell-${i}-${key}`} value={key}>{key}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {_.map(importData.slice(0, 15), (row, i) => (
              <TableRow key={`table-import-table-row-${i}`}>
                {_.map(row, (cell, j) => (
                  <TableCell key={`table-import-table-row-${i}-cell-${j}`}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button {...buttonSaveProps}>{t('app:common:execute')}</Button>
    </>
  );
};

TableImport.propTypes = {
  formErrors: PropTypes.instanceOf(Object),
  formData: PropTypes.instanceOf(Object),
  sendForm: PropTypes.func.isRequired,
  keys: PropTypes.instanceOf(Array),
  // setImportData: PropTypes.func,
  importCallback: PropTypes.func,
  importData: PropTypes.instanceOf(Array),
  // title: PropTypes.string,
};

TableImport.defaultProps = {
  formErrors: [],
  formData: [],
  keys: [],
  importData: [],
  // setImportData() {},
  importCallback() {},
  // title: '',
};

export default TableImport;
