import React from 'react';
import PropTypes from 'prop-types';

import { v4 } from 'uuid';

import {
  Box,
  Checkbox,
  TableCell,
  TableRow,
  TableSortLabel,
} from '@mui/material';

const TableHead = (props) => {
  const { order, orderBy, useDrag } = props;
  const { head } = props;
  const { onRequestSort } = props;
  const { onSelectAllClick } = props;
  const { numSelected } = props;
  const { rowCount } = props;
  const { showCheckbox } = props;

  const createSortHandler = (property) => (event) => onRequestSort(property, event);

  if (!head.length) {
    return null;
  }

  const tableCheckboxCellProps = {
    padding: 'checkbox',
  };
  const checkboxProps = {
    color: 'primary',
    indeterminate: numSelected > 0 && numSelected < rowCount,
    checked: rowCount > 0 && numSelected === rowCount,
    onChange: onSelectAllClick,
    inputProps: {
      'aria-label': 'select all desserts',
    },
    sx: {
      p: 0,
    },
  };

  return (
    <TableRow>
      {showCheckbox ? (
        <TableCell {...tableCheckboxCellProps}>
          <Checkbox {...checkboxProps} />
        </TableCell>
      ) : null}

      {head.map((cell) => {
        const cellId = /* cell.id || */ v4();
        const cellName = cell.name || cellId;
        const tableCellProps = {
          key: cellId,
          align: cell.align || 'left',
          padding: cell.disablePadding ? 'none' : 'normal',
          sortDirection: orderBy === cellName ? order : false,
          colSpan: cell.colSpan || 1,
        };

        if (cell.width) {
          tableCellProps.width = cell.width;
        }

        if (cell.sort) {
          const tableSortLabelProps = {
            active: orderBy === cellName,
            direction: orderBy === cellName ? order : 'asc',
            onClick: createSortHandler(cellName),
          };
          const boxProps = {
            component: 'span',
            sx: {
              border: 0,
              clip: 'rect(0 0 0 0)',
              height: '1px',
              margin: -1,
              overflow: 'hidden',
              padding: 0,
              position: 'absolute',
              whiteSpace: 'nowrap',
              width: '1px',
            }, // visuallyHidden
          };

          return (
            <TableCell {...tableCellProps}>
              <TableSortLabel {...tableSortLabelProps}>
                {cell.label}
                {orderBy === cellId
                  ? (<Box {...boxProps}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>)
                  : null}
              </TableSortLabel>
            </TableCell>
          );
        }

        return (<TableCell {...tableCellProps}>{cell.label}</TableCell>);
      })}

      {useDrag ? <TableCell width={57} /> : null}
    </TableRow>
  );
};

TableHead.propTypes = {
  head: PropTypes.instanceOf(Array),
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onSelectAllClick: PropTypes.func,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  showCheckbox: PropTypes.bool,
};

TableHead.defaultProps = {
  head: [],
  onSelectAllClick() {},
  numSelected: 0,
  rowCount: 0,
  showCheckbox: false,
};

export default TableHead;
