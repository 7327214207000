import React from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';

import { Tabs, Tab } from '@mui/material';

const TabsForm = (props) => {
  const { form } = props;
  const { setForm } = props;

  const { name } = props;
  const { values } = props;

  const index = _.findIndex(values, (item) => item.value === form[name]);
  const tabsProps = {
    value: index > -1 ? index : 0,
    onChange(event, newValue) {
      if (name && values[newValue]) {
        if (values[newValue].value) {
          setForm({
            ...form || {},
            page: 1,
            [name]: values[newValue].value,
          });
        } else if (typeof values[newValue].onClick === 'function') {
          values[newValue].onClick();
        }
      }
    },
    // 'aria-label': 'Support tabs',
    variant: 'scrollable',
    scrollButtons: 'auto',
    allowScrollButtonsMobile: true,
    sx: {
      minHeight: 'auto',
      '& .MuiTabs-indicator': { display: 'none' },
    },
  };

  return (
    <Tabs {...tabsProps}>
      {(values || []).map((item) => (
        <Tab
          key={v4()}
          label={item.name}
          sx={{
            p: '4px 8px',
            minHeight: 'auto',
            fontSize: '12px',
            minWidth: 'auto',
          }}
        />
      ))}
    </Tabs>
  );
};

export default TabsForm;
